import React, {useState} from 'react';
import {useStyles} from './styles';
import TableRow from './table_row';
import UpdateUserModal from '../../modals/update_user_modal';

const UsersTable = () => {
    const [isUpdateModal, setIsUpdateModal] = useState(false)

    const {
        wrapper,
        headerWrapper,
        statusWrapper,
        userWrapper,
        walletWrapper,
        pointsWrapper,
    } = useStyles()

    return (
        <div className={wrapper}>
            <div className={`container ${headerWrapper}`}>
                <div className={userWrapper}>User</div>
                <div className={walletWrapper}>Wallet address</div>
                <div className={statusWrapper}>Status</div>
                <div className={pointsWrapper}>Revenue</div>
            </div>
            <div>
                <TableRow updateUserData={() => setIsUpdateModal(true)} />
                <TableRow updateUserData={() => setIsUpdateModal(true)} />
                <TableRow updateUserData={() => setIsUpdateModal(true)} />
                <TableRow updateUserData={() => setIsUpdateModal(true)} />
                <TableRow updateUserData={() => setIsUpdateModal(true)} />
            </div>
            {/* {isUpdateModal && <UpdateUserModal
                onClose={() => setIsUpdateModal(false)}
            />} */}
        </div>
    );
};

export default UsersTable;