import React, {useState} from 'react';
import {useStyles} from './styles';
import avatarImage from '../../../../../../assets/img/avatar.png'
import CopyIcon from '../../../../../common/Icons/copy_icon';
import {USERS_STATUS_LIST} from '../../../../../../static_content/dropdowns_data';
import Button from '../../../../../common/button';
import EditIcon from '../../../../../common/Icons/edit_icon';
import UserStatusDropdown from '../../../../../common/users_status_dropdown';
import DotsButtonWithDropdown from '../../../../../common/dots_button_with_dropdown';
import {Link} from 'react-router-dom';

const walletKey = '0xds4f54df4sd5654654d6s54f6s'

const TableRow = ({updateUserData}: {updateUserData: () => void;}) => {
    const {
        wrapper,
        rowWrapper,
        pointsWrapper,
        statusWrapper,
        walletWrapper,
        userWrapper,
        dataWrapper,
        actionsWrapper,
        reactionButton,
    } = useStyles()

    const [activeStatus, setActiveStatus] = useState<USERS_STATUS_LIST>(USERS_STATUS_LIST.ACTIVE)

    const copyHandle = (value: string) => {
        navigator.clipboard.writeText(value)
    }

    return (
        <div className={wrapper}>
            <div className={`container ${rowWrapper}`}>
                <div className={dataWrapper}>
                    <Link to='users_list/123' className={userWrapper}>
                        <img src={avatarImage} alt=""/>
                        <p>Username</p>
                    </Link>
                    <div
                        className={walletWrapper}
                        onClick={() => copyHandle(walletKey)}
                    >
                        {walletKey}
                        <CopyIcon />
                    </div>
                    <div className={statusWrapper}>
                        <UserStatusDropdown
                            onChange={(value) => setActiveStatus(value)}
                            activeOption={activeStatus}
                        />
                    </div>
                    <div className={pointsWrapper}>
                        65.01
                    </div>
                </div>
                <div className={actionsWrapper}>
                    <button className={`${reactionButton} checked`}>
                        👍 2.5k
                    </button>
                    <button className={`${reactionButton}`}>
                        👎 148
                    </button>
                    <Button
                        onClick={updateUserData}
                        type='outlined'
                    >
                        <EditIcon />
                    </Button>
                    <DotsButtonWithDropdown items={[
                        {title: 'Give red status', onClick: () => console.log(1)},
                        {title: 'Delete', onClick: () => console.log(1)}
                    ]} />
                </div>
            </div>
        </div>
    );
};

export default TableRow;